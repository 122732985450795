import * as React from "react";
import * as styles from "./features.module.css";
import Section from "../components/section";
import Heading from "../components/heading";
import * as register from "../images/registudy/register.png";
import * as report from "../images/registudy/report.png";
import * as tags from "../images/registudy/tags.png";
import * as planner from "../images/registudy/planner.png";
import Fade from "react-reveal/Fade";
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Button, Modal } from "react-bootstrap"
import RegistudyTestimonials from "../components/RegistudyTestimonials"

export default function RegistudyFeatures({home = false}) {

  const heading = "Registudy"

  const content = [
    {
      primaryText: "Registro Simplificado de Atividades Educacionais",
      secondaryText: "Transforme a educação domiciliar em uma experiência sem esforço com o nosso sistema intuitivo de registro de atividades. O Registudy oferece uma solução abrangente que permite aos pais documentar facilmente cada passo do progresso educacional de seus filhos. Desde lições de matemática até projetos de ciências, capture cada detalhe com facilidade. Inclua registros fotográficos para ilustrar o trabalho desenvolvido. Com o Registudy, registrar as atividades educacionais em casa nunca foi tão fácil.",
      image: register,
    },
    {
      primaryText: "Relatórios Detalhados ao Alcance dos Seus Dedos",
      secondaryText: "Com o Registudy, criar relatórios educacionais detalhados é um processo simples e rápido. Nossa plataforma gera automaticamente relatórios personalizáveis que refletem o progresso e as conquistas de cada aluno. Esses relatórios são perfeitos para manter um registro oficial do aprendizado, para apresentações em avaliações educacionais ou simplesmente para acompanhar o progresso ao longo do tempo. Impressione com relatórios visualmente atraentes e informativos que destacam o sucesso e o crescimento dos seus filhos.",
      image: report,
    },
    {
      primaryText: "Planner: Organize a Rotina de Homeschooling com Facilidade",
      secondaryText: "O Registudy eleva a organização do homeschooling a um novo patamar com o recurso Planner. Essa funcionalidade permite que as famílias cadastrem e gerenciem atividades educacionais em uma agenda interativa e fácil de usar. Planeje a rotina semanal ou mensal com facilidade, adicionando tarefas, lições e projetos em datas específicas. Com o Planner do Registudy, você tem uma visão clara e estruturada da rotina educacional, garantindo que nenhum aspecto importante seja esquecido e contribuindo para uma experiência de aprendizado mais organizada e produtiva.",
      image: planner,
    },
    {
      primaryText: "Organização Eficiente com Sistema de Tags",
      secondaryText: "Mantenha todas as atividades educacionais perfeitamente organizadas com nosso sistema inovador de tags. O Registudy permite que os pais categorizem e localizem atividades específicas com facilidade, usando tags personalizadas. Seja por assunto, tipo de atividade ou qualquer outro critério que você escolher, nosso sistema de tags oferece uma maneira flexível e eficiente de manter tudo organizado. Encontre rapidamente o que você precisa e mantenha o foco no que é mais importante: a educação dos seus filhos.",
      image: tags,
    }
  ]

  const Image = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "registudy/app_with_planner.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    if (!data?.placeholderImage?.childImageSharp?.fluid) {
      return <div>Picture not found</div>
    }

    return (
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        fadeIn
        durationFadeIn={5000}
      />
    )
  }

  return (
    <Section>
      <Heading center className="cl-identity">{heading}</Heading>
      <br />
      <br />
      <Image />
      <br />
      <br />
      <br />
      <p className="text-center">
        Descubra a liberdade e a eficácia na educação dos seus filhos com o Registudy, a plataforma projetada
        especialmente para pais homeschoolers.
        <br />
        <br />
        Nossa ferramenta inovadora simplifica o registro e acompanhamento de atividades educacionais, tornando o
        aprendizado em casa mais organizado e eficiente do que nunca.
        <br />
        <br />
        Com o Registudy, você tem o poder de personalizar a educação dos seus filhos, criando relatórios detalhados e
        utilizando um sistema de tags intuitivo para organizar o aprendizado de forma única.
        <br />
        <br />
        Junte-se a inúmeras famílias e transforme a experiência educacional do seu lar com facilidade, eficiência e
        flexibilidade!
      </p>
      {home && (
        <div className={styles.buttonContainer}>
          <Button
            as={Link}
            to={"/registudy"}
            className="btn-lg bg-identity text-white font-weight-bold text-decoration-none hovbg-darkviolet border-0">
            Saiba mais...
          </Button>
        </div>
      )}
      {!home && (
        <div>
          <div className={styles.content}>
            {content.map((item, i) => (
              <RegistudyFeature
                key={i}
                {...item}
                orientation={i % 2 === 0 ? "default" : "reverse"}
              />
            ))}
          </div>
          <h3 className="text-center cl-identity font-weight-bold">
            Dê o Próximo Passo na Educação dos Seus Filhos com o Registudy
          </h3>
          <br />
          <p className="text-center">
            Está na hora de elevar a experiência de aprendizado dos seus filhos. <strong>Clique no botão
            no final da página</strong> e
            faça parte da
            comunidade Registudy hoje!
            <br />
            <br />
            Ao se inscrever, você desbloqueia um mundo de recursos que transformarão a educação domiciliar. Nossa
            plataforma
            é fácil de usar, incrivelmente eficiente e projetada para se adaptar ao estilo único de aprendizado de cada
            criança.
            <br />
            <br />
            Com Registudy, você não apenas acompanha o progresso educacional dos seus filhos, mas também participa
            ativamente da construção de um futuro brilhante para eles. Não espere mais. <strong>Assine agora</strong> e
            comece a jornada para
            um aprendizado domiciliar revolucionário!
          </p>
          <br />
          <div>
            <b>Investimento</b>
            <br />
            <h2 className="cl-identity font-weight-bold">R$ 27,90 por mês</h2>
          </div>

          <br />
          <div>
            <br />
            <h1 className="text-center cl-registudy font-weight-bold">Teste GRÁTIS por 30 dias!</h1>
            <p className="text-center font-weight-bold">Conheça a plataforma, experimente os recursos e cobraremos no
              seu cartão de crédito somente após 30 dias.</p>
            <p className="text-center font-weight-bold font-italic">(cancele a qualquer momento).</p>
          </div>
          <div className="text-center mt-5">
            <a
              href="https://registudy.com"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-lg bg-identity text-white font-weight-bold text-decoration-none hovbg-darkviolet"
            >
              Assinar Registudy
            </a>
          </div>
        </div>
      )}
      <br />
      <br />
      <RegistudyTestimonials />
    </Section>
  );
}

function RegistudyFeature({
                            primaryText,
                            secondaryText,
                            image,
                            orientation = "default"
                          }) {
  const [showModal, setShowModal] = React.useState(false);
  const orientationStyle = orientation === "default" ? "" : styles.reverse;
  const classImage = orientation === "default" ? styles.imageLeftApp : styles.imageRightApp;

  const props = {
    duration: 2000,
    left: orientation === "default",
    right: orientation === "reverse",
  }

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Fade {...props} duration={2000}>
      <div className={`${styles.featureContainer} ${orientationStyle}`}>
        <div className={styles.copyColumn}>
          <div className={styles.copyContainer}>
            <h4 className={styles.primaryText}>
              {primaryText}
            </h4>
            <p className={styles.secondaryText}>
              {secondaryText}
            </p>
          </div>
        </div>
        <div className={styles.imageColumn}>
          <img className={classImage} src={image} alt={primaryText} onClick={handleImageClick} />
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-fullscreen">
        <Modal.Body style={{
          height: '90vh',
          width: '100%',
          overflowX: 'scroll', // Habilita scroll horizontal
          overflowY: 'scroll', // Esconde scroll vertical
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            minWidth: '100vw', // Garante que o contêiner interno tenha pelo menos a largura da tela
          }}>
            <img
              src={image}
              alt={primaryText}
              style={{
                maxHeight: '100vh', // Restringe a altura máxima da imagem
                objectFit: 'contain'
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Fade>
  );
}
