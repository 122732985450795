import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Heading from "../heading"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image001 from "../../images/registudy-testimonials/001.jpeg"
import image002 from "../../images/registudy-testimonials/002.jpeg"
import image003 from "../../images/registudy-testimonials/003.jpeg"
import image004 from "../../images/registudy-testimonials/004.jpeg"
import image005 from "../../images/registudy-testimonials/005.jpeg"

const RegistudyTestimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  const images = [
    image001,
    image002,
    image003,
    image004,
    image005,
  ];

  return (
    <>
      <Heading center className="cl-identity mt-4 mb-4">Depoimentos</Heading>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index}>
              <Image src={img} alt={`Testimonial ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </SliderContainer>
    </>
  );
};

const SliderContainer = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 90vh;
  margin: auto;
  display: block;
`;

export default RegistudyTestimonials;
